<template>
    <div class="app-container">
        <el-tabs v-model="activeName" type="border-card">
            <el-tab-pane label="馆组信息" name="first">
                <div class="library-details" >
                    <div class="left">
                        <div class="txt">馆组名称：</div>
                        <div class="txt">所属馆组：</div>
                        <div class="txt">分馆类型：</div>
                        <div class="txt">分馆地址：</div>
                        <div class="txt">联系电话：</div>
                        <div class="txt">营利性质：</div>
                        <div class="txt">创建时间：</div>
                        <div class="txt">到期时间：</div>
                        <div class="txt">分馆介绍：</div>
                    </div>
                    <div class="right">
                        <div class="txt1">{{ libraryDetailsList.branchName }}</div>
                        <div class="txt1">{{ libraryDetailsList.groupName }}</div>
                        <div class="txt1">{{ libraryDetailsList.profitableNature }}</div>
                        <div class="txt1">{{ libraryDetailsList.branchPosition }}</div>
                        <div class="txt1">{{ libraryDetailsList.phone }}</div>
                        <div class="txt1">{{ libraryDetailsList.profitableNature }}</div>
                        <div class="txt1">{{ libraryDetailsList.createTime }}</div>
                        <div class="txt1">{{ libraryDetailsList.expireTime }}</div>
                        <div class="txt1">{{ libraryDetailsList.branchIntro }}</div>
                    </div>
                </div>
            </el-tab-pane>

            <el-tab-pane label="成员管理" name="second">
                <!-- 功能按钮组 -->
                <el-row :gutter="20" style="margin-bottom: 7px">
                    <el-col :span="1.5">
                        <el-button type="primary" size="small" icon="el-icon-plus" plain
                            @click="handleAdd">添加分馆成员</el-button>
                    </el-col>
                </el-row>

                <!-- 表格数据 -->
                <el-table ref="table" :data="tableData" @selection-change="handleSelectionChange">
                    <el-table-column prop="createTime" label="加入时间" width="180px" />
                    <el-table-column prop="username" label="姓名" width="180px" />
                    <el-table-column prop="bid" label="角色权限" width="160px">
                        <template slot-scope="scope">
                            {{ scope.row.bid == 0 ? '总馆图书管理员' : '分馆图书管理员' }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="phone" label="电话号码" />
                    <!-- <el-table-column prop="email" label="邮箱" show-overflow-tooltip /> -->
                    <el-table-column label="操作" width="100px">
                        <template slot-scope="scope">
                            <div v-if="username == scope.row.username">
                                <el-button size="mini" icon="el-icon-delete" type="info" plain
                                >暂无</el-button>
                            </div>
                            <div v-else>
                                <el-button size="mini" icon="el-icon-delete" type="danger" plain
                                @click="handleDelete(scope.row)">移除</el-button>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>

                <!-- 分页 -->
                <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    :current-page="page.current" :page-size="page.size" :page-sizes="[2, 5, 10, 20, 100]" :page-count="page.pageNum"
                    layout="total, sizes, prev, pager, next, jumper" :total="page.total">
                </el-pagination>
            </el-tab-pane>
        </el-tabs>

        <!-- 编辑或添加对话框 -->
        <el-dialog :title="dialogTitle" :modal="false" :visible.sync="dialogFormVisible" width="50%" append-to-body>
            <el-form ref="dialogForm" :model="dialogForm" label-width="80px" inline>
                <el-form-item label="姓名" prop="username">
                    <el-input v-model="dialogForm.username" autocomplete="off" />
                </el-form-item>
                <el-form-item label="账号" prop="account" v-if="false" >
                    <el-input v-model="dialogForm.account" autocomplete="off"/>
                </el-form-item>
                <el-form-item v-show="dialogType == 'add'" label="密码" prop="password">
                    <el-input v-model="dialogForm.password" autocomplete="off" />
                </el-form-item>
                <el-form-item label="电话号码" prop="phone">
                    <el-input v-model="dialogForm.phone" maxlength="11" autocomplete="off" />
                </el-form-item>
                <el-form-item label="地址" prop="address" v-if="false">
                    <el-input v-model="dialogForm.address" autocomplete="off" />
                </el-form-item>
                <el-form-item label="邮箱" prop="email" v-if="false">
                    <el-input v-model="dialogForm.email" autocomplete="off" value="dialogForm.phone+'@163.com'" />
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancelDialog">取 消</el-button>
                <el-button type="primary" @click="submitDialog">确 定</el-button>
            </div>
        </el-dialog>

    </div>
</template>
  
<script>
import { listAdmin, addManager, deleteAdmin } from '@/api/admin'
import { libraryDetailsData, AdmindetailsData } from '@/api/library'
import {getAid,getBid} from  '@/api/library'
import { getUserName } from '@/utils/auth'

export default {
    data() {
        return {
            // =============馆组信息===========
            editFlag: false,
            LabMsg: [],
            // 总馆详情
            details: {},
            //当前官族的人员名字
            username:"",


            // 馆组信息
            libraryDetailsList: {},
            // 查询表单数据
            queryForm: {},
            // 按钮禁用
            editDisabled: true,
            removeDisabled: true,
            // 表格数据
            tableData: [],
            // 多选数据
            multipleSelection: [],
            // 分页数据
            page: {
                current: 1, // 当前页
                size: 10, // 每页显示数
                total: undefined , // 总计
                pageNum:1 //页数
            },
            // 对话框类型[add|edit]
            dialogType: '',
            // 对话框是否可见
            dialogFormVisible: false,
            // 对话框标题
            dialogTitle: undefined,
            // 对话框数据
            dialogForm: {
                account: "",
                email: "",
                password: "",
                phone: "",
                username: ""
            },
            activeName: "first",
        }
    },
    created() {
        // this.Admindetails();
        this.librariesDetails()
        this.getList()
        this.username = JSON.parse(getUserName())
        //console.log(this.username)
    },
    updated(){
       if (this.tableData == []){
        const queryParams = { ...this.queryForm, page:1, size: this.page.size }
            listAdmin(queryParams).then(({ data }) => {
                this.tableData = data.adminData
                this.page.total = data.total
                this.page.current = data.current
                this.page.pageNum = data.pageNum
            })
       }
    },
    methods: {
        // ================馆组信息=============
        editMsg() {
            this.editFlag = true
        },
        editMsgConfirm() {
            let queryData = { ...this.LabMsg };
            libraryEdit(queryData).then(res => {
                if (res.code == "200") {
                    alert("修改成功");
                    this.Admindetails()
                    this.editFlag = false
                }
            })
        },
        editMsgReturn() {
            this.editFlag = false
        },

        // 馆组信息
        Admindetails() {
            AdmindetailsData({}).then(({ data }) => {
                this.details = data;
                // 需要修改的馆组信息
                this.LabMsg = data;
            })
        },





        //============== 分馆API =============
        // 馆组信息
        librariesDetails() {
            libraryDetailsData().then(({ data }) => {
                console.log(data);
                this.libraryDetailsList = data;
                // 需要修改的馆组信息
                this.LabMsg = data;
            })
        },
        // 查询分馆列表
        getList() {
            const queryParams = { ...this.queryForm, page: this.page.page, size: this.page.size }
            listAdmin(queryParams).then(({ data }) => {
                this.tableData = data.adminData
                this.page.total = data.total
                this.page.current = data.current
                this.page.pageNum = data.pageNum
            })
        },
        // 添加分馆
        addData() {
            console.log(this.dialogForm)
            //const params = {}
            this.dialogForm.account=this.dialogForm.phone
            this.dialogForm.email = this.dialogForm.phone+"@163.com"
            addManager(this.dialogForm).then(({ data }) => {
                
                this.msgSuccess(data)
                this.getList()
            })
        },
        // 修改分馆
        updateData() {
            updateReader(this.dialogForm).then(() => {
                this.msgSuccess('修改成功!')
                this.getList()
            })
        },
        // 删除分馆
        deleteData(arr) {
            deleteAdmin(arr).then(({ data }) => {
                this.msgSuccess(data)
                this.getList()
            })
        },

        //============== 查询表单 =================
        // 查询表单提交
        querySubmit() {
            this.getList()
        },
        // 重置表单
        resetForm(formName) {
            this.$refs[formName].resetFields()
            this.getList()
        },

        //============== 表格 ==================
        // 多选改变
        handleSelectionChange(val) {
            this.multipleSelection = val
            this.editDisabled = !(val.length === 1)
            this.removeDisabled = !(val.length > 0)
        },
        // 删除行数据
        handleDelete(row) {
            //console.log(row.username+"===============")
            let arr = [row.id]
            this.$confirm('此操作将永久移除该成员, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
                .then(() => {
                    this.deleteData(arr)
                })
                .catch(() => { })
        },
        // 添加数据
        handleAdd() {
            // 清除对话框表单数据
            this.dialogForm = {}
            this.dialogType = 'add'
            this.dialogTitle = '添加分馆图书管理员'
            this.showDialog()
        },
        // 编辑数据
        handleEdit(val) {
            this.dialogForm = val
            this.dialogType = 'edit'
            this.dialogTitle = '修改分馆'
            this.showDialog()
        },

        //============== 添加或编辑对话框 ===================
        // 显示对话框
        showDialog() {
            this.dialogFormVisible = true
        },
        // 对话框取消
        cancelDialog() {
            this.dialogFormVisible = false
        },
        // 对话框提交
        submitDialog() {
            if (this.dialogType == 'add') {
                this.addData()
            } else {
                this.updateData()
            }
            this.dialogFormVisible = false
        },

        //=============== 分页 ==================
        // 每页显示数改变
        handleSizeChange(val) {
            this.page.size = val
            this.getList()
        },
        // 当前页改变
        handleCurrentChange(val) {
            this.page.page = val
            this.getList()
        }
    }
}
</script>
  
<style scoped>
.library-details .left {
    margin-left: auto;
    display: flex;
    flex-direction: column;
}

.library-details .right {
    margin-right: auto;
    display: flex;
    flex-direction: column;
}

.txt {
    color: #858585;
    line-height: 2.5rem;
    font-size: 14px;
    display: flex;
    text-align: right;
    margin-right: 20px;
}

.txt1 {
    line-height: 2.5rem;
    font-size: 14px;
    text-align: right;
    min-width: 150px;
}

.setting {
    font-weight: 700;
    font-size: 16px;
    color: #17233d;
}

.setting-item {
    color: #606266;
    font-weight: 500;
    line-height: 1;
    font-size: 14px;
    margin-right: 20px;
}

.bottom {
    position: fixed;
    bottom: 0;
    padding: 15px 30px;
    border: 1px solid #DCDFE6;
    background-color: #FFF;
    border-radius: 5px;
    width: 79%;
}
</style>