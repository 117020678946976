import request from '@/utils/request'

// 查询管理员
export function listAdmin(params) {
    return request({
      url: '/admin/find',
      method: 'get',
      params
    })
  }
  //添加总馆成员
export function addManager(data) {
  return request({
    url: '/admin/add',
    method: 'post',
    data
  })
}
  //添加分馆成员
export function addAdmin(data) {
  return request({
    url: '/admin/addBranchByGroup',
    method: 'post',
    data
  })
}
  //删除管理员
  export function deleteAdmin(data) {
    return request({
      url: '/admin/delete',
      method: 'post',
      data
    })
  }